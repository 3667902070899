import React, { useEffect, useState } from "react";
import {
  base_url,
  search_profile,
  update_profile
} from "constants/endpoints";
import { useParams } from "react-router-dom";
import Sidebar from "components/admin/SideBars/Sidebar";
import Swal from "sweetalert2";
import { useAdminAuth } from "store/AdminAuth";
import Header from "components/admin/Headers/Header";
import CmrCorrectionModal from "./CmrCorrectionModal";
import Loader from "components/Loader";
import verifyUser from "utils/VerifyUser";

export default function UserDetails() {
  const { id } = useParams();
  const getAuth = useAdminAuth((state) => state.auth);
  const [userDetails, setUserDetails] = useState({})
  const [loading, setLoading]=useState(false)
  const [editFirstName, setEditFirstName] = useState(false)
  const [editLastName, setEditLastName] = useState(false)
  const [editMiddleName, setEditMiddleName] = useState(false)
  const [editIdNumber, setEditIdNumber] = useState(false)
  const [editPhone, setEditPhone] = useState(false)
  const [editProfileType, setEditProfileType] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)
  
  useEffect(()=>{
      setUserDetails({})
      setLoading(true);
      let token = getAuth.token;
      let url = `${base_url}${search_profile}${id}`;
      try {
        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((jsonResp) => {
            console.log(jsonResp.data[0]);
            if (jsonResp.data == null) {
              setLoading(false);
            }
            if (jsonResp.data != null) {
              setUserDetails(jsonResp.data[0]);
              setLoading(false);
            }
          });
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
  }, [])

  const handleChange = (e)=>{
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
  }

  const Commit =()=>{
      setLoading(true);
      let token = getAuth.token;
      let url = `${base_url}${update_profile}${userDetails.Id}`;
      let data={
        first_name:userDetails?.first_name?.toUpperCase(),
        middle_name:userDetails?.middle_name?.toUpperCase(),
        last_name:userDetails?.last_name?.toUpperCase(),
        id_type:userDetails.id_type,
        id_number:userDetails.id_number,
        phone:userDetails.phone,
        email:userDetails.email,
        state:userDetails.current_state,
        lg:userDetails.current_lg,
        address:userDetails.current_address?.toUpperCase()
      }
      try {
        fetch(url, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((jsonResp) => {
            console.log(jsonResp.data);
            if(jsonResp.data==true){
              Swal.fire({
                title: "User Updated!",
                text: "",
                icon: "success",
              }).then(()=>{
                window.location.reload()
              })
            }else{
              Swal.fire({ title: "Failed!", text: jsonResp.message, icon: "error" }).then(()=>{setLoading(false)});
            }
          });
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
  }

  const handleBlur = (e)=>{
    // setEditLastName(false)
    console.log(e.target.name)
    if (e.target.name=="first_name"){
      setEditFirstName(false)
    }else if(e.target.name=="middle_name"){
      setEditMiddleName(false)
    }else if (e.target.name=="last_name"){
      setEditLastName(false)
    }else if(e.target.name=="id_number"){
      setEditIdNumber(false)
    }else if(e.target.name=="id_type"){
    }else{}


  }
  if (!getAuth.token || getAuth.token === "" || getAuth.token === undefined) {
    window.location = "/admin/login";
    return;
  }
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR Motor Vehicle Information Request Details" />
        {/* <ul className="ml-8 flex flex-wrap text-lg pb-4 font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"> */}
        {/* <li className="mr-2 cursor-pointer"></li>
          <li className="mr-2"></li> */}
        {/* </ul> */}

        <div className="bg-white mt-6 py-8">
          <div className="px-8 mt-1 w-full text-lg leading-9 overflow-x-auto">
            <div className="flex justify-between">
              <button
                onClick={() => {
                  window.history.back();
                }}
                className="hidden-print bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
              >
                &larr; Go Back
              </button>
              {/* <button
                className="hidden-print bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
                onClick={() => toggleModal()}
              >
                Make Corrections
              </button> */}
            </div>
            <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
              Owner Details <span className="bg-green-700 rounded p-1 cursor-pointer hover:bg-black h-7 text-sm" onClick={()=>{Commit()}}>{loading ? <Loader /> : "Save Changes "}</span>
              {/* | <span className="cursor-pointer hover:text-black" onClick={()=>{!syncing && SyncroniseOwner()}}>{syncing ? 'Syncing...' : 'Syncronise'}</span> */}
            </div>
            <div className="table-container">
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">First Name: <span className="text-blue-800 font-semibold text-sm cursor-pointer" onDoubleClick={() => setEditFirstName(true)}>{userDetails.first_name=="" && !editFirstName && "Add"}</span></td>
                      <td>
                        {!editFirstName ? (
                          <span
                            className="text-blue-800 font-semibold inline-edit uppercase"
                            onDoubleClick={() => setEditFirstName(true)}
                          >
                            {userDetails.first_name}
                          </span>
                        ) : (
                          <input
                            type="text"
                            className="rounded-lg h-8 uppercase inline-edit"
                            name="first_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={userDetails.first_name || ""} 
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Profile Type:</td>
                      <td className="text-blue-800 font-semibold">
                        {userDetails.profile_type === "individual" &&
                          "Personal"}
                        {userDetails.profile_type === "corporate" &&
                          "Organisation"}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Phone:</td>
                      <td className="text-blue-800 font-semibold">
                        {userDetails.phone}
                      </td>
                    </tr>

                    <tr>
                      <td className="table-headers">Id Number:</td>
                      <td>
                        {!editIdNumber ? (
                          <span
                            className="text-blue-800 font-semibold inline-edit"
                            onDoubleClick={() => setEditIdNumber(true)}
                          >
                            {userDetails.id_number}
                          </span>
                        ) : (
                          <>
                            <input
                            type="text"
                            className="rounded-lg h-8 uppercase inline-edit"
                            name="id_number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={userDetails.id_number || ""} 
                          />
                          </>
                          
                        )}
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="table-headers">Address:</td>
                      <td className="text-blue-800 font-semibold">
                        {userDetails.current_address}
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Last Name: <span className="text-blue-800 font-semibold text-sm cursor-pointer" onDoubleClick={() => setEditLastName(true)}>{userDetails.last_name=="" && !editLastName && "Add"}</span> </td>
                      <td>
                        {!editLastName ? (
                          <span
                            className="text-blue-800 font-semibold inline-edit uppercase"
                            onDoubleClick={() => setEditLastName(true)}
                          >
                            {userDetails.last_name}
                          </span>
                        ) : (
                          <input
                            type="text"
                            className="rounded-lg h-8 uppercase inline-edit"
                            name="last_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={userDetails.last_name || ""}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Gender:</td>
                      <td className="text-blue-800 font-semibold">
                        {userDetails.gender}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">State:</td>
                      <td className="text-blue-800 font-semibold">
                        {userDetails.current_state}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Name on ID:</td>
                      <td className="text-blue-800 font-semibold">
                        {userDetails.id_number_name}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Middle Name: <span className="text-blue-800 font-semibold text-sm cursor-pointer" onDoubleClick={() => setEditMiddleName(true)}>{userDetails.middle_name=="" && !editMiddleName && "Add"}</span></td>
                      <td>
                        {!editMiddleName ? (
                          <span
                            className="text-blue-800 font-semibold inline-edit uppercase"
                            onDoubleClick={() => setEditMiddleName(true)}
                          >
                            {userDetails.middle_name}
                          </span>
                        ) : (
                          <input
                            type="text"
                            className="rounded-lg h-8 uppercase inline-edit"
                            name="middle_name"
                            value={userDetails.middle_name || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Email:</td>
                      <td className="text-blue-800 font-semibold">
                        {userDetails.email}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">L.G.A:</td>
                      <td className="text-blue-800 font-semibold">
                        {userDetails.current_lg}
                      </td>
                    </tr>

                    <tr>
                      <td className="table-headers">Validation Status:</td>
                      <td className="text-blue-800 font-semibold">
                        {userDetails.status}
                      {/* {userDetails.status  != "Verified" && 
                      <>
                      <button 
                        className="bg-blue-600 text-white text-sm px-1 h-6 ml-2 rounded-lg hover:bg-black"
                        onClick={() => {setIsVerifying(true); verifyUser(userDetails.Id, getAuth.token)
                          .then(data => {
                            console.log("Verification successful:", data);
                            if(data.statusCode===0){
                              Swal.fire({
                                title: "Success!",
                                text: "User verified",
                                icon: "success",
                              }).then(() => {
                                setIsVerifying(false);
                                window.location.reload();
                              })
                            }else{
                              Swal.fire({
                                title: "Failed!",
                                text: "User verification failed",
                                icon: "error",
                              }).then(() => {
                                setIsVerifying(false);
                              })
                            }
                            // Handle successful verification (e.g., update UI)
                          })
                          .catch(error => {
                            console.error("Verification failed:", error);
                            // Handle verification errors
                            setIsVerifying(false);
                          })}}>
                          {isVerifying==false ? 'Verify':<Loader />}
                          </button>
                      </>
                      } */}
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
            <div className="table-headers">Address:
              <span className="text-blue-800 font-semibold ml-12">
                {userDetails.current_address}
              </span>
            </div>
            <hr />
            {/* <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
              Vehicle Details <span className="bg-gray-700 rounded cursor-pointer hover:text-red-500 hover:bg-gray-100 text-lg px-1 ml-3" 
                onClick={() => {
                  if(requestDetails.vehicleStatus=="Stolen"){
                    Swal.fire({
                      title: "Error!",
                      text: "Can not initiate correction for stolen vehicle",
                      icon: "error",
                    })
                    return
                  }
                  toggleModal();}}>Initiate Correction</span>
            </div> */}
            {/* <div className="table-container">
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Licence Plate:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.licensePlate}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Manufacturer:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.manufacturer}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Vehicle Type:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.vehicleType}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Watchlist Status:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.vehicleStatus}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Chassis Number:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.chassisNumber}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Model:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.model}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Color:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.color}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">License State:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.mla}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Engine Number:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.engineNumber}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Year:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.year}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Vehicle Usage:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.vehicleUsage}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}
            <hr />
            {/* <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
              Requests Details {} Select Type
              <select 
                name="request" 
                className="rounded-lg text-black ml-4 h-9 text-sm "
                onChange={(e) => {
                  setRequest(e.target.value);
                }}  
              >
              <option value="">Select Request Type</option>
                <option value="CMR Information">CMR Information</option>
                <option value="Change of Ownership">Change of Ownership</option>
              </select>
            </div> */}
            <div>
            {/* <table width={'100%'}> */}
              {/* <thead>
                <tr className="text-left">
                  <th>Date</th>
                  <th>Expiry Date</th>
                  <th>Request Number</th>
                  <th>Request State</th>
                  <th>Certificate Number</th>
                  <th>Command Center</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead> */}
              {/* {selectedRequest === "CMR Information" && (
                <tbody>
                  {cmrinfo.map((item, index) => (
                    <tr key={index}>
                      <td>{formatDateNew(item.entry_date)}</td>
                      <td>{formatDateNew(item.expiry_date)}</td>
                      <td>{item.request_number}</td>
                      <td>{item.request_state}</td>
                      <td>{item.certificate_number}</td>
                      <td>{item.command_center}</td>
                      <td className={`${item.status=="Approved" ? "text-green-600":"text-red-600"} font-bold`}>{item.status}</td>
                      <td>
                      {item.status=="Approved" && 
                        <button
                          className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900 ml-3`}
                          value={item.id}
                          disabled={false}
                          onClick={() => {
                          let url = public_base+"/cert/"+item.id
                          window.open(url,'_blank', 'rel=noopener noreferrer')
                        }}
                      >
                        View Certificate
                      </button>
                      }
                        
                      </td>
                    </tr>
                  ))}
                </tbody>
              )} */}
            {/* </table> */}
            </div>
            {/* <hr /> */}
          </div>
        </div>
      </div>

      <CmrCorrectionModal />
    </div>
  );
}
