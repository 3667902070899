import React, { useEffect, useState } from "react";
import {
  public_base,
  base_url,
  vehicle_details,
  pre_cmr_details,
  correct_cmr_initiate,
  approval_layer2,
  synccmrinfo
} from "constants/endpoints";
import Modal from "components/Modal";
import { useParams } from "react-router-dom";
import Sidebar from "components/admin/SideBars/Sidebar";
import Swal from "sweetalert2";
import { useAdminAuth } from "store/AdminAuth";
import useModalStore from "store/ModalState";
import Header from "components/admin/Headers/Header";
import { formatDateNew } from "utils/date_format";
import CmrCorrectionModal from "./CmrCorrectionModal";
import SwitchVehicleOwner from "./SwitchVehicleOwner";
import Loader from "components/Loader";
import { useOldCorrectionStore } from "store/OldValuesStore";
import Model from "./Model";
import { HasAccess } from "@permify/react-role";

export default function VehicleDetails() {
  const { id } = useParams();
  const getAuth = useAdminAuth((state) => state.auth);
  const toggleCorrectionModal = useModalStore((state) => state.toggleModal);
  const { modals, toggleModal, setModalState } = useModalStore();

  const handleToggleModal = (id) => {
    toggleModal(id);
  };
  
  const correctionDetails = useOldCorrectionStore(
    (state) => state.correctionDetails
  );
  const setCorrectionDetails = useOldCorrectionStore(
    (state) => state.setCorrectionDetails
  );
  const [requestDetails, setRequestDetails] = useState({});
  // const [correctionOldValues, setCorrectionOldValues] = useState({
  //   vehicle_type: "",
  //   engine_number: "",
  //   mla: "",
  //   year: "",
  //   color: "",
  // });

  const updateOldValues = (newValues) => {
    setCorrectionDetails((prevValues) => ({
      ...prevValues,
      ...newValues,
    }));
  };
  const [syncing, setSyncing] = useState(false);
  const [cmrinfo, setCMRInfo] = useState([])
  const [cowinfo, setCOWInfo] = useState([])
  const [selectedRequest, setRequest] = useState("")
  const [vehicleCorrectionModal, setVehicleCorrectionModal] = useState(false)

  const SyncroniseOwner = (e, id) => {
    e.preventDefault();
    setSyncing(true);
    // alert("testing");
    try {
      let url = base_url + synccmrinfo + id + "/"+ requestDetails.ownerId
      fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAuth.token,
        },
      })
        .then((response) => {
          if (response.ok) {
            // console.log("repsonse ok");
            return response.json();
          } else {
            // console.log("repsonse not ok");
            Swal.fire({
              title: "Error!",
              text: "Error Syncronising Data!",
              icon: "error",
            }).then(() => {
              window.history.back();
            });
          }
        }).then((respJson)=>{
          // console.log(respJson)
          if (respJson.data === true) {
            Swal.fire({
              title: "Success!",
              text: respJson.message,
              icon: "success",
            })
            return
          }else{
            Swal.fire({
              title: "Error!",
              text: respJson.message,
              icon: "error",
            })
          }
        })

    }catch(err){
      console.log(err)
    }
  };

  useEffect(() => {
    let token = getAuth.token;
    // console.log(token);
    // console.log(base_url + pre_cmr_details + id);
    try {
      fetch(base_url + vehicle_details + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          // console.log(response.json().data);
          if (response.ok) {
            // console.log("repsonse ok");
            return response.json();
          } else {
            // console.log("repsonse not ok");
            Swal.fire({
              title: "Error!",
              text: "Error fetching data from the server!",
              icon: "error",
            }).then(() => {
              window.history.back();
            });
          }
        })
        .then((jsonResp) => {
          console.log(jsonResp.data);
          let cmr_info = jsonResp.data.products.cmr_info;
          let cow_info = jsonResp.data?.products?.change_of_ownership;
          // console.log(cmr_info);
          setRequestDetails({
            ownerId: jsonResp.data.owner_id,
            firstName: jsonResp.data.owner.first_name,
            middleName: jsonResp.data.owner.middle_name,
            lastName: jsonResp.data.owner.last_name,
            gender: jsonResp.data.owner.gender,
            phone: jsonResp.data.owner.phone,
            email: jsonResp.data.owner.email,
            state: jsonResp.data.owner.present_address,
            residentialState: jsonResp.data.owner.state,
            residentialLg: jsonResp.data.owner.lga,
            residentialAddress: jsonResp.data.owner.present_address,
            profileType: jsonResp.data.owner.type,
            licensePlate: jsonResp.data.license_plate,
            chassisNumber: jsonResp.data.chassis_number,
            engineNumber: jsonResp.data.engine_number,
            manufacturer: jsonResp.data.manufacturer,
            model: jsonResp.data.model,
            year: jsonResp.data.year,
            vehicleType: jsonResp.data.vehicle_type,
            color: jsonResp.data.color,
            vehicleUsage: jsonResp.data.vehicle_usage,
            vehicleStatus: jsonResp.data.vehicle_status,
            mla: jsonResp.data.mla,
          });
          setCorrectionDetails({
            vehicle_type: jsonResp?.data?.vehicle_type,
            engine_number: jsonResp?.data?.engine_number,
            mla: jsonResp?.data?.mla,
            year: jsonResp?.data?.year,
            color: jsonResp?.data?.color,
            manufacturer: jsonResp?.data?.manufacturer,
            model: jsonResp?.data?.model,
            vehicle_status: jsonResp?.data?.vehicle_status,
            vehicle_usage: jsonResp?.data?.vehicle_usage,
            license_plate: jsonResp?.data?.license_plate,
            chassis_number: jsonResp?.data?.chassis_number,
          });
          // setUserInputVehicle(jsonResp.data);
          setCMRInfo(cmr_info)
          setCOWInfo(cow_info);
        });
    } catch (e) {
      Swal.fire({
        title: "Error!",
        text: "Error fetching data from the server!",
        icon: "error",
      });
    }
  }, []);

  if (!getAuth.token || getAuth.token === "" || getAuth.token === undefined) {
    window.location = "/admin/login";
    return;
  }
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 bg-blueGray-600">
        <Header title="CMR Motor Vehicle Information Request Details" />
        {/* <ul className="ml-8 flex flex-wrap text-lg pb-4 font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"> */}
        {/* <li className="mr-2 cursor-pointer"></li>
          <li className="mr-2"></li> */}
        {/* </ul> */}

        <div className="bg-white mt-6 py-8">
          <div className="px-8 mt-1 w-full text-lg leading-9 overflow-x-auto">
            <div className="flex justify-between">
              <button
                onClick={() => {
                  window.history.back();
                }}
                className="hidden-print bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
              >
                &larr; Go Back
              </button>
              {/* <button
                className="hidden-print bg-blue-400 text-white px-2 py-1 rounded hover:bg-blue-600 cursor-pointer hover:text-black"
                onClick={() => toggleModal()}
              >
                Make Corrections
              </button> */}
            </div>
            <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
              Owner Details 
              {/* | <span className="cursor-pointer hover:text-black" onClick={()=>{!syncing && SyncroniseOwner()}}>{syncing ? 'Syncing...' : 'Syncronise'}</span> */}
            </div>
            <div className="table-container">
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">First Name:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.firstName}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Profile Type:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.profileType === "individual" &&
                          "Personal"}
                        {requestDetails.profileType === "organisation" &&
                          "Organisation"}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Phone:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.phone}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Address:</td>
                      <td className="text-blue-800 font-semibold">
                        {`requestDetails`.residentialAddress}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Last Name:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.lastName}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Gender:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.gender}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">State:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.residentialState}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Middle Name:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.middleName}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Email:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.email}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">L.G.A:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.residentialLg}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
              Vehicle Details <span className="bg-gray-700 rounded cursor-pointer hover:text-red-500 hover:bg-gray-100 text-lg px-1 ml-3" 
                onClick={() => {
                  if(requestDetails.vehicleStatus=="Stolen"){
                    Swal.fire({
                      title: "Error!",
                      text: "Can not initiate correction for stolen vehicle",
                      icon: "error",
                    })
                    return
                  }
                    handleToggleModal('correction');}}>Initiate Correction
                  </span>
                  <HasAccess
                    roles={["super-admin"]}
                    // permissions="view-formation"
                    renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
                  >
                    <span className="bg-gray-700 rounded cursor-pointer hover:text-red-500 hover:bg-gray-100 text-lg px-1 ml-3"
                      onClick={()=>{
                        handleToggleModal("switchowner")
                      }}
                    >
                      Switch Owner
                    </span>
                  </HasAccess>
            </div>
            <div className="table-container">
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Licence Plate:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.licensePlate}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Manufacturer:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.manufacturer}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Vehicle Type:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.vehicleType}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Watchlist Status:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.vehicleStatus}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Chassis Number:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.chassisNumber}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Model:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.model}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Color:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.color}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">License State:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.mla}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td className="table-headers">Engine Number:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.engineNumber}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Year:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.year}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-headers">Vehicle Usage:</td>
                      <td className="text-blue-800 font-semibold">
                        {requestDetails.vehicleUsage}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            <div className="mt-4 rounded-lg text-xl font-bold text-gray-100 py-2 px-4 bg-blue-400">
              Requests Details {} Select Type
              <select 
                name="request" 
                className="rounded-lg text-black ml-4 h-9 text-sm "
                onChange={(e) => {
                  setRequest(e.target.value);
                }}  
              >
              <option value="">Select Request Type</option>
                <option value="CMR Information">CMR Information</option>
                <option value="Change of Ownership">Change of Ownership</option>
              </select>
            </div>
            <div>
            <table width={'100%'}>
              <thead>
                <tr className="text-left">
                  <th>Date</th>
                  <th>Expiry Date</th>
                  <th>Request Number</th>
                  <th>Request State</th>
                  <th>Certificate Number</th>
                  <th>Command Center</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {selectedRequest === "CMR Information" && (
                <tbody>
                  {cmrinfo?.map((item, index) => (
                    <tr key={index}>
                      <td>{formatDateNew(item.entry_date)}</td>
                      <td>{formatDateNew(item.expiry_date)}</td>
                      <td>{item.request_number}</td>
                      <td>{item.request_state}</td>
                      <td>{item.certificate_number}</td>
                      <td>{item.command_center}</td>
                      <td className={`${item.status=="Approved" ? "text-green-600":"text-red-600"} font-bold`}>{item.status}</td>
                      <td>
                      {item.status=="Approved" && 
                        <button
                          className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900 ml-3`}
                          value={item.id}
                          disabled={false}
                          onClick={() => {
                          let url = public_base+"/cert/"+item.id
                          window.open(url,'_blank', 'rel=noopener noreferrer')
                        }}
                      >
                        View Certificate
                      </button>
                      }
                      <HasAccess
                        roles={["super-admin"]}
                        // permissions="view-formation"
                        renderAuthFailed={<p className="text-red-700 text-4xl mx-8"></p>}
                      >
                        <button
                            className={`bg-yellow-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900 ml-3`}
                            value={item.id}
                            disabled={false}
                            onClick={(e)=>{SyncroniseOwner(e, item.id)}}
                        >
                          Syncronise
                        </button>
                      </HasAccess>
                        
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}

              {selectedRequest === "Change of Ownership" && (
                <tbody>
                  {cowinfo && cowinfo.length > 0 ? (
                    cowinfo.map((item, index) => (
                      <tr key={index}>
                        <td>{formatDateNew(item.entry_date)}</td>
                        <td>{formatDateNew(item.expiry_date)}</td>
                        <td>{item.request_number}</td>
                        <td>{item.request_state}</td>
                        <td>{item.certificate_number}</td>
                        <td>{item.command_center}</td>
                        <td
                          className={`${
                            item.status === "Approved" ? "text-green-600" : "text-red-600"
                          } font-bold`}
                        >
                          {item.status}
                        </td>
                        <td>
                          {item.status === "Approved" && (
                            <button
                              className={`bg-green-400 rounded px-3 py-1 text-black-400 hover:text-gray-100 hover:bg-gray-900 ml-3`}
                              value={item.id}
                              disabled={false}
                              onClick={() => {
                                let url = public_base + "/cow/" + item.id;
                                window.open(url, "_blank", "rel=noopener noreferrer");
                              }}
                            >
                              View Certificate
                            </button>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">No Change of ownership for this vehicle</td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
            </div>
          </div>
        </div>
      </div>

      <CmrCorrectionModal />
      <SwitchVehicleOwner vehicle_id={id} />
    </div>
  );
}
